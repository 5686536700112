import React from 'react'
import { GetStaticPaths, GetStaticProps } from 'next'
import { When } from 'react-if'
import { paths, slugs } from '@/constants'
import { Durations } from '@/constants/durations'
import { ProjectMetaProvider } from '@/contexts/ProjectMetaContext'
import { TheatricalRegionProvider } from '@/contexts/TheatricalRegionContext/TheatricalRegionContext'
import { Seo } from '@/layout/Seo'
import { MovieJsonLd } from '@/layout/Seo/JsonLd'
import { NextPageWithLayout } from '@/layouts'
import { TheatricalFooter, TheatricalFooterProps } from '@/organisms/TheatricalFooter'
import { getWebClient } from '@/services/ApolloClient'
import { getTheatricalReleaseBySlug } from '@/services/CmsService'
import {
  CatalogMovie,
  mapJsonLdProps,
  formatTitleFriendlyReleaseDate,
  formatTitleReleaseYear,
  fetchTitle,
  mapSeoProps,
} from '@/services/ContentCatalog'
import { getPlayableLivestreamsByProject, PlayableLivestream } from '@/services/LivestreamService'
import { getContentfulProjectTheme } from '@/services/ProjectThemeService'
import { getProjectAbout, ProjectMeta } from '@/services/ProjectsService'
import { getTheatricalRelease } from '@/services/Theatrical'
import {
  formatFilmName,
  getCurrentYear,
  getTheatricalPreReleasePageData,
  GetTheatricalPreReleasePageDataResult,
  isProjectThatsBothSeriesAndMovie,
  useMoviesTitle,
} from '@/services/TheatricalPreReleaseService'
import { getWhereToWatch, WhereToWatch } from '@/services/WhereToWatchService'
import { ProjectTheme, TheatricalRegion } from '@/types/codegen-contentful'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getStaticPropsErrorHandler, getUrlFromThisFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { omitUndefineds } from '@/utils/object'
import { inTheaters, isStreaming } from '@/utils/phases'
import { redact } from '@/utils/sensitive-data'
import { loadTranslations } from '@/utils/translate/translate-server'
import { WatchTheatricalView } from '@/views/WatchTheatricalView'
import { WatchTheatricalContextProvider } from '@/views/WatchTheatricalView/WatchTheatricalContext'

export const MoviesPage: NextPageWithLayout<
  ProjectMeta & {
    allTheatricalReleaseRegions: TheatricalRegion[]
    catalogTitle?: CatalogMovie
    inTheaters: boolean
    isStreaming: boolean
    livestreams: PlayableLivestream[] | null
    locale: string
    pageData: GetTheatricalPreReleasePageDataResult
    whereToWatch: WhereToWatch
    contentfulProjectTheme: ProjectTheme
    supportedTheatricalRegions: TheatricalRegion[]
    canShowTickets?: boolean
  }
> = (props) => {
  const { filmDetails } = props.pageData
  const { inTheaters, isStreaming, locale } = props

  const path = `${paths.movies.index}/${props.slug}`
  const catalogTitle = props.catalogTitle
  const title = useMoviesTitle({
    filmDetails,
    filmName: formatFilmName(catalogTitle),
    inTheaters,
    isStreaming: props.slug === slugs.cabrini || props.slug === slugs.sight || isStreaming,
    releaseYear: formatTitleReleaseYear(catalogTitle) || getCurrentYear(),
    releaseDate: formatTitleFriendlyReleaseDate(props.locale, catalogTitle),
  })

  return (
    <>
      <Seo {...mapSeoProps({ path, title, catalogTitle, locale })} noindex={!props.public} path={path} />
      {/* TODO: soon, all pages will have catalog titles, and this can be removed */}
      {catalogTitle && <MovieJsonLd {...mapJsonLdProps({ path, catalogTitle, locale })} />}
      <ProjectMetaProvider project={props}>
        <TheatricalRegionProvider
          supportedTheatricalRegions={props.supportedTheatricalRegions}
          regionData={props.allTheatricalReleaseRegions}
        >
          <WatchTheatricalContextProvider pageData={props.pageData}>
            <WatchTheatricalView
              catalogTitle={catalogTitle}
              livestreams={props.livestreams}
              whereToWatch={props.whereToWatch}
              contentfulProjectTheme={props.contentfulProjectTheme}
              canShowTickets={props.canShowTickets}
            />
            <When condition={props.slug === slugs.soundOfFreedom}>
              <TheatricalFooter {...(filmDetails?.filmAssociationLogos as TheatricalFooterProps)} />
            </When>
          </WatchTheatricalContextProvider>
        </TheatricalRegionProvider>
      </ProjectMetaProvider>
    </>
  )
}

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({ preview = false, params }) =>
  getStaticPropsErrorHandler({ requestUrl: getUrlFromThisFilePath(params) }, async () => {
    const locale = getLocaleFromParams(params)
    const project = (params?.projectSlug as string).toLowerCase()

    const client = getWebClient({ locale })
    let projectMeta = await getProjectAbout({ slug: project }, client, { locale, preview })

    if (project === slugs.truthAndConviction) {
      return {
        redirect: {
          destination: paths.shows.truthAndConviction,
          permanent: false,
        },
      }
    }

    if (!projectMeta.hasHubPage) {
      return {
        redirect: {
          destination: `${paths.watch.index}/${project}`,
          permanent: false,
        },
      }
    }

    if (projectMeta.projectType !== 'movie' && !isProjectThatsBothSeriesAndMovie(projectMeta)) {
      return {
        notFound: true,
        revalidate: Durations.FIFTEEN_MINUTES_IN_SECONDS,
      }
    }

    const livestreams = await getPlayableLivestreamsByProject({ projectSlug: project }, client)
    const shouldIncludePreview = process.env.NEXT_PUBLIC_ANGEL_ENV === 'development'
    const pageData = await getTheatricalPreReleasePageData({ slug: project }, client, { locale, preview })
    const catalogTitle = await fetchTitle<CatalogMovie>(locale, pageData.contentCatalogId)

    const theatricalRelease = await getTheatricalReleaseBySlug({
      theatricalSlug: project,
      opts: { preview: shouldIncludePreview, locale },
    })
    const theatricalReleaseMono = await getTheatricalRelease({ theatricalSlug: project, client })

    const ticketsStatus = theatricalReleaseMono?.region?.ticketsStatus
    const canShowTickets = Boolean(ticketsStatus && (ticketsStatus === 'PRE_SALES' || ticketsStatus === 'LIVE'))

    const allTheatricalReleaseRegions = theatricalRelease?.regionCollection || null
    const supportedTheatricalRegions = theatricalRelease?.supportedTheatricalRegions || null
    const whereToWatch = await getWhereToWatch(project, preview)
    const contentfulProjectTheme = await getContentfulProjectTheme(project, preview)

    // Projects may have a hub page and not be in Hydra so these values are undefined
    if (projectMeta.slug === undefined) {
      projectMeta = { ...projectMeta, slug: project }
    }

    if (projectMeta.public === undefined || (!projectMeta.public && catalogTitle)) {
      projectMeta = { ...projectMeta, public: true }
    }

    return {
      props: {
        allTheatricalReleaseRegions,
        catalogTitle: omitUndefineds(catalogTitle),
        inTheaters: inTheaters(projectMeta.phases),
        isStreaming: isStreaming(projectMeta.phases),
        livestreams,
        locale,
        pageData,
        supportedTheatricalRegions,
        whereToWatch,
        contentfulProjectTheme,
        canShowTickets,
        ...redact(projectMeta, ['phases']),
        ...(await loadTranslations(locale, [
          'common',
          'home',
          'watch',
          'app-promo',
          'ads',
          'account',
          'theatrical-presales',
          'pif',
          'guild',
        ])),
      },
      revalidate: catalogTitle ? Durations.FIFTEEN_MINUTES_IN_SECONDS : Durations.ONE_MINUTE_IN_SECONDS,
    }
  })

export default MoviesPage
