import React from 'react'
import classNames from 'classnames'
import { LegacyImage as Image } from '@/atoms/LegacyImage'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useTranslate } from '@/utils/translate/translate-client'

export interface TheatricalFooterProps {
  mpaa?: {
    cloudinaryPath: string
    height: number
    width: number
  }
  billing?: {
    cloudinaryPath: string
    height: number
    width: number
  }
  includeAtom?: boolean
  includeFandango?: boolean
}

export const TheatricalFooter: React.FC<TheatricalFooterProps> = ({ mpaa, billing, includeAtom, includeFandango }) => {
  const { isDarkMode } = useThemeContext()
  const { t } = useTranslate('theatrical-presales')

  return (
    <section
      className={classNames(
        'relative z-10',
        isDarkMode ? 'bg-core-gray-950 text-gray-100 py-16' : 'bg-white text-gray-950',
      )}
    >
      <PaddedContainer>
        <div className="flex flex-col items-center justify-between gap-8 text-center text-gray-500 sm:grid sm:grid-cols-2 sm:gap-2 lg:flex lg:flex-row">
          <div className="order-2 mx-auto flex flex-col gap-2 sm:order-3 lg:order-2">
            {mpaa && (
              <Image
                layout="fixed"
                alt="MPAA"
                src={`${mpaa.cloudinaryPath}.webp`}
                height={mpaa.height}
                width={mpaa.width}
              />
            )}
            {billing && (
              <Image
                alt={t('billing', 'Billing')}
                layout="fixed"
                src={`${billing.cloudinaryPath}.webp`}
                height={billing.height}
                width={billing.width}
              />
            )}
          </div>
          {includeAtom && (
            <div className="order-3 mx-auto sm:order-2 lg:order-3">
              <Image
                alt="Atom Tickets"
                layout="fixed"
                src="/v1685994266/angel-studios/sound-of-freedom/atom.webp"
                height={56}
                width={173}
              />
            </div>
          )}
          {includeFandango && (
            <div className="order-4 mx-auto">
              <Image
                alt="Fandango"
                layout="fixed"
                src="/v1685994258/angel-studios/sound-of-freedom/fandango.webp"
                height={40}
                width={222}
              />
            </div>
          )}
        </div>
      </PaddedContainer>
    </section>
  )
}
